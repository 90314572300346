import LoopService from "./LoopService";

export default function ServicesGrid({ items = [] }) {
  return (
    items.length > 0 && (
      <div className="services-grid">
        {items.map((item) => {
          return (
            <LoopService title={item.title} description={item.description} />
          );
        })}
      </div>
    )
  );
}
