import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Spacer from "../components/Spacer";

export default function Home() {
  const navigate = useNavigate();

  return (
    <div className="home page">
      <header>
        <h1>Bolkas Anastasios</h1>
        <Spacer height="10px" />
        <h2>Software Developer</h2>
      </header>
      <Spacer height="20px" />
      <h2>A few words about me:</h2>
      <p>
        I started back in 2019 as a web developer, building simple websites.
      </p>
      <p>
        As I progressed through time, my skills grew and their variety got
        bigger, leading to me building customized websites and eshops.
      </p>
      <p>
        Now, I am able to completely engineer the architecture of a website,
        eshop or mobile application and create it from scratch, while also
        connecting them with each other.
      </p>
      <p>
        Because of this progress, I have decided to take things to the next
        level and move on by starting my own business, aiming to provide Online
        Solutions to companies.
      </p>
      <p>
        Of course, a well-done job can only be achieved through a combination of
        skills and knowledge. That is why I am always backed up by my team. We
        are 5 people who each knows a subject very well - web development,
        mobile development, web design, marketing and social media.
      </p>
      <p>
        Together, we can build anything your business needs to benefit from the
        Internet's market.
      </p>
      <Spacer height="20px" />
      <Button onClicked={() => navigate("/contact")} text="Plan your project" />
      <Spacer height="20px" />
    </div>
  );
}
