export default function LoopService({
  image = null,
  title = null,
  description = null,
  fromPrice = null,
}) {
  return (
    <div className="loop-service">
      {image && <img src={image} alt={title} />}
      {title && <h2>{title}</h2>}
      {description && <h3>{description}</h3>}
      {fromPrice && (
        <div className="from-price">
          Starting at: <bdi>{fromPrice}</bdi>
        </div>
      )}
    </div>
  );
}
