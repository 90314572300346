import { Link, useLocation } from "react-router-dom";
import IconHome from "../icons/IconHome";

export default function Navigation() {
  const currentLocation = useLocation();

  return (
    <div className="navigation">
      <nav>
        <Link
          to="/"
          className={`${currentLocation.pathname == "/" ? "current" : ""}`}
        >
          <IconHome />
        </Link>
        <Link
          to="/services"
          className={`${
            currentLocation.pathname == "/services" ? "current" : ""
          }`}
        >
          Services
        </Link>
        <Link
          to="/projects"
          className={`${
            currentLocation.pathname == "/projects" ? "current" : ""
          }`}
        >
          Projects
        </Link>
        <Link
          to="/contact"
          className={`${
            currentLocation.pathname == "/contact" ? "current" : ""
          }`}
        >
          Contact
        </Link>
      </nav>
    </div>
  );
}
