import { useSearchParams } from "react-router-dom";
import Spacer from "../components/Spacer";
import Divider from "../components/Divider";
import ProjectsGrid from "../components/ProjectsGrid";
import { useState } from "react";

export default function Projects() {
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get("category") || "";
  const [projects, setProjects] = useState([
    {
      title: "Marmaridis Furniture",
      description:
        "Eshop with Loyalty Card System, customized Shipping Cost Calculator and Mobile Application.",
      link: "https://marmaridis.gr/",
      categories: ["eshops", "applications"],
    },
    {
      title: "Almeco 10Deka",
      description: "Eshop with custom order system and product customizer.",
      link: null,
      categories: "eshops",
    },
    {
      title: "Wepack",
      description:
        "Eshop with sale per product quantity, customized product categorization, customized product filtering and Pylon ERP connection for products synchronization.",
      link: "https://wepack.gr/",
      categories: "eshops",
    },
    {
      title: "Rudolf Hellas",
      description: "Presentation website for chemical company.",
      link: "https://rudolf-hellas.gr/",
      categories: "websites",
    },
    {
      title: "Saneco",
      description:
        "Mobile Application for disinfections company Saneco with contract making and employees' actions history.",
      link: null,
      categories: "applications",
    },
    {
      title: "Billia",
      description: "Custom website.",
      link: "https://billia.gr",
      categories: "websites",
    },
    {
      title: "Gavanas Bakery",
      description:
        "Custom Presentation Website for large-scale baking products company.",
      link: "https://gavanasbakery.gr/",
      categories: "websites",
    },
    {
      title: "Bizios Dairy",
      description:
        "Custom Presentation Website for large-scale dairy products company.",
      link: "https://bizios.gr/",
      categories: "websites",
    },
    {
      title: "Mevgal Maniacs Contest",
      description: "Contest with entry code and draw for the winner.",
      link: "https://mevgalmaniacs.gr/diagonismos/",
      categories: "applications",
    },
    {
      title: "Skiron",
      description: "Custom Presentation website in multiple languages.",
      link: "https://skiron.gr/",
      categories: "websites",
    },
    {
      title: "The SCENT",
      description:
        "Custom E-shop construction with specialized filter system and flexible code.",
      link: "https://thescent.gr/",
      categories: "eshops",
    },
    {
      title: "Delmare - Properties Management",
      description: "Custom Presentation Website with Online Booking System.",
      link: "https://delmare.gr/",
      categories: "websites",
    },
    {
      title: "Polykatoikein",
      description:
        "Custom Animated Presentation website for property management company.",
      link: "https://polykatoikein.gr/",
      categories: "websites",
    },
    {
      title: "Antithesis Clothing E-Shop",
      description: "Custom Eshop for Clothing Company Antithesis.",
      link: "https://antithesisclothing.gr/",
      categories: "eshops",
    },
    {
      title: "Alberta",
      description:
        "Creation of a custom made website, with animated elements and product presentation.",
      link: "https://alberta.gr/",
      categories: "websites",
    },
    {
      title: "B2B Halvas Papastavrou",
      description: "Custom Presentation Website.",
      link: "https://www.halvaspapastavrou.gr/b2b/",
      categories: "websites",
    },
    {
      title: "Orama",
      description: "Custom Presentation Website.",
      link: "https://oramagreece.com/",
      categories: "websites",
    },
    {
      title: "Pablo Beauty",
      description:
        "Presentation website & E-shop, through the NSRF e-lianiko program",
      link: "https://pablobeauty.gr/",
      categories: "eshops",
    },
  ]);

  // Filter counts based on category
  const websiteCount = projects.filter((project) =>
    project.categories.includes("websites")
  ).length;

  const eshopCount = projects.filter((project) =>
    project.categories.includes("eshops")
  ).length;

  const applicationsCount = projects.filter((project) =>
    project.categories.includes("applications")
  ).length;

  const filterByCategory = (newCategory) => {
    if (newCategory === "") {
      searchParams.delete("category"); // Remove the category filter if "All" is selected
    } else {
      searchParams.set("category", newCategory); // Set the new category
    }
    setSearchParams(searchParams);
  };

  return (
    <div className="projects page">
      <header>
        <h1>Projects</h1>
        <Spacer height="10px" />
        <h2>Projects I have been assigned to build up</h2>
      </header>
      <Spacer height="20px" />
      <h3>
        All below projects are built by me while working at{" "}
        <a href="https://iloveit.gr/" target="_blank" rel="noopener noreferrer">
          iLoveIt Digital Agency
        </a>
        .
      </h3>
      <Spacer height="20px" />
      <div className="filters row">
        <div
          className={category === "" ? "current" : ""}
          onClick={() => filterByCategory("")}
        >
          All ({projects.length})
        </div>
        <div
          className={category === "websites" ? "current" : ""}
          onClick={() => filterByCategory("websites")}
        >
          Websites ({websiteCount})
        </div>
        <div
          className={category === "eshops" ? "current" : ""}
          onClick={() => filterByCategory("eshops")}
        >
          Eshops ({eshopCount})
        </div>
        <div
          className={category === "applications" ? "current" : ""}
          onClick={() => filterByCategory("applications")}
        >
          Applications ({applicationsCount})
        </div>
      </div>
      <Spacer height="20px" />
      <ProjectsGrid items={projects} currentFilter={category} />
    </div>
  );
}
