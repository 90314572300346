import Spacer from "../components/Spacer";
import IconEmail from "../icons/IconEmail";
import IconPhone from "../icons/IconPhone";

export default function Contact() {
  return (
    <div className="contact page">
      <header>
        <h1>Get in touch</h1>
        <Spacer height="10px" />
        <h2>Let's plan your next project</h2>
      </header>
      <div className="row">
        <div className="column">
          <Spacer height="40px" />
          <a
            href="mailto:bolkas.anastasios@gmail.com"
            className="link-with-icon"
          >
            <IconEmail />
            <span>bolkas.anastasios@gmail.com</span>
          </a>
          <Spacer height="10px" />
          <a href="tel:00306982849189" className="link-with-icon">
            <IconPhone />
            <span>6982849189</span>
          </a>
        </div>
        <div className="column"></div>
      </div>
    </div>
  );
}
