import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./sections/home";
import Projects from "./sections/projects";
import SingleProject from "./components/SingleProject";
import Contact from "./sections/contact";
import Navigation from "./components/Navigation";
import Services from "./sections/services";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const updateAppHeight = () => {
      const appElement = document.getElementById("App");
      const navigationElement = document.querySelector(".navigation");

      // if (window.innerWidth < 1024 && appElement && navigationElement) {
      const newHeight = window.innerHeight - navigationElement.offsetHeight;
      appElement.style.height = `${newHeight}px`;
      // } else {
      // appElement.style.removeProperty("height"); // Reset height if width is above 1024px
      // }
    };

    // Run on window resize and initial load
    window.addEventListener("resize", updateAppHeight);
    updateAppHeight();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", updateAppHeight);
  }, []);

  return (
    <Router>
      <div id="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects">
            <Route index element={<Projects />} />
            <Route path=":uuid" element={<SingleProject />} />
          </Route>
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Navigation />
      </div>
    </Router>
  );
}

export default App;
